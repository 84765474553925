<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                <CButton
                  @click="goToCreatePromo"
                  color="durianprimary"
                  class="px-2 float-right mb-3"
                  v-element-access="permissions.promoCreate"
                >
                  <CIcon name="cil-library-add" />
                  &nbsp;{{ $t("PROMOS.CREATE") }}
                </CButton>
              </CCol>
            </CRow>

            <CDataTable hover :items="promos" :fields="fields">
              <template #status="data">
                <td>
                  <CBadge :color="getPromoBadge(data.item.status)">
                    {{
                    data.item.status
                    }}
                  </CBadge>
                </td>
              </template>
              <template #discount_type="data">
                <td>
                  <CBadge :color="getPromoTypeBadge(data.item.discount_type)">
                    {{
                    data.item.discount_type
                    }}
                  </CBadge>
                </td>
              </template>
              <template #discount="data">
                <td>{{ getAppend(data.item.discount, data.item.discount_type, data.item.currency) }}</td>
              </template>
              <template #deleteButton="data">
                <td>
                  <CButton
                    color="lightborder"
                    class="delete-btn px-1 float-right"
                    size="sm"
                    @click="showDeleteConfirmation(data.item)"
                  >
                    <CIcon name="cil-x-circle" />
                    &nbsp;&nbsp;{{ $t("PROMOS.DELETE_BUTTON") }}
                  </CButton>
                  <CButton
                    v-show="data.item.status == 'inactive'"
                    size="sm"
                    color="lightborder"
                    class="regen-btn px-3 float-right mr-2"
                    @click="editPromo(data.item)"
                  >{{ $t("PROMOS.EDIT_BUTTON") }}</CButton>
                  <CButton
                    v-show="data.item.status == 'inactive'"
                    color="lightborder"
                    class="regen-btn px-3 float-right mr-2"
                    size="sm"
                    @click="showActivateConfirmation(data.item)"
                  >{{ $t("PROMOS.ACTIVATE_BUTTON") }}</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      :title="$t('PROMOS.DELETE_CONFIRM')"
      color="danger"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="deleteConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.DELETE_CONFIRM_MSG")}}</label>
      </CContainer>
      <template #footer>
        <CButton
          @click="deleteConfirmationModal = false"
          color="lightborder"
        >{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton @click.prevent="deletePromo()" color="lightborder">{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>
    <CModal
      :title="$t('PROMOS.ACTIVATE_CONFIRM')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="activateConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.ACTIVATE_CONFIRM_MSG")}}</label>
      </CContainer>
      <template #footer>
        <CButton
          @click="activateConfirmationModal = false"
          color="lightborder"
        >{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton @click.prevent="activatePromo()" color="lightborder">{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>
    <EditPromo
      v-if="showEditModal"
      @updatelist="updatelist"
      :editPromoModal.sync="showEditModal"
      :id="id"
    />
  </div>
</template>

<script>
import { listPromo, deletePromo, activatePromo } from "@/api/card_promo.api.js";
import EditPromo from "./EditPromo";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Promos";
export default {
  name: COMPONENT_NAME,
  components: {
    EditPromo
  },
  data() {
    return {
      deleteConfirmationModal: false,
      activateConfirmationModal: false,
      totalRows: 0,
      fields: [
        {
          key: "label",
          label: this.$t("PROMOS.TABLE.LABEL")
        },
        { key: "type", label: this.$t("PROMOS.TABLE.TYPE") },
        { key: "discount_type", label: this.$t("PROMOS.TABLE.DISCOUNT_TYPE") },
        { key: "discount", label: this.$t("PROMOS.TABLE.DISCOUNT") },
        { key: "status", label: this.$t("PROMOS.TABLE.STATUS") },
        { key: "deleteButton", label: "" }
      ],
      pageNumber: 1,
      limit: 50,
      promos: [],
      showEditModal: false,
      id: ""
    };
  },
  methods: {
    updatelist() {
      this.getPromos();
    },
    editPromo(item) {
      this.id = item.id;
      this.showEditModal = true;
    },
    showDeleteConfirmation(item) {
      this.id = item.id;
      this.deleteConfirmationModal = true;
    },
    showActivateConfirmation(item) {
      this.id = item.id;
      this.activateConfirmationModal = true;
    },
    pageChange() {},
    goToCreatePromo() {
      this.$router.push("promos/new");
    },
    getAppend(val, type, prefix) {
      switch (type) {
        case "percentage":
          return val + "%";
        case "flat":
          return this.getCurrencyLabel(prefix, val);
      }
    },
    getPromos() {
      listPromo()
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.promos = response.data.data;
          } else {
            this.promos = [];
          }
        })
        .catch(this.handleAPIError);
    },
    deletePromo() {
      deletePromo(this.id)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.getPromos();
          }
          this.deleteConfirmationModal = false;
        })
        .catch(this.handleAPIError);
    },
    activatePromo() {
      activatePromo(this.id)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.getPromos();
          }
          this.activateConfirmationModal = false;
        })
        .catch(this.handleAPIError);
    }
  },
  mounted() {
    this.getPromos();
  },
  created() {
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.getPromos);
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.initializeData);
  },
  beforeDestroy(){
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style>
</style>
