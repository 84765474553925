<template>
  <div>
    <CModal
      :title="$t('PROMOS.EDIT')"
      color="light"
      size="lg"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="showEditModal"
    >
      <CContainer>
        <ValidationObserver ref="validationObserver">
          <CForm>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.PROMO_TYPE") }}</CCol>
              <ValidationProvider
                name="Promo Type"
                ref="Promo Type"
                rules="required"
                v-slot="{ errors }"
                class="form-group col-6"
              >
                <b-form-input
                  class="col-12 mb-0"
                  id="promoType"
                  name="promoType"
                  v-model="promo.type"
                  type="text"
                  disabled
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CODE") }}</CCol>
              <ValidationProvider
                name="Promo Label"
                ref="Promo Label"
                rules="minmax:6,25"
                v-slot="{ errors }"
                class="form-group col-6"
              >
                <b-form-input
                  class="col-12 mb-0"
                  id="promolabel"
                  name="promolabel"
                  v-model="promo.label"
                  type="text"
                  @blur="verifyPromoLabel(promo.label)"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CURRENCY") }}</CCol>
              <span class="form-group col-sm-9">
                <ValidationProvider name="Currency" :rules="`required`" v-slot="{ errors }">
                  <b-form-select v-model="promo.currency" :options="currencyOptions()"></b-form-select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </span>
            </CRow>

            <CRow v-show="showPromoChannel">
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CHANNELS") }}</CCol>
              <ValidationProvider
                name="Promo Channels"
                :rules="`requiredPromoChan:${showPromoChannel}`"
                v-slot="{ errors }"
                class="form-group col-6 p-2"
              >
                <b-form-checkbox-group
                  v-model="promoChannel"
                  :options="options"
                  class="col-12"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-checkbox-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>

            <CRow v-show="showBankList">
              <CContainer class="p-2 mx-3 border border-light rounded">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.BANK") }}</CCol>
                  <ValidationProvider
                    name="Bank"
                    :rules="`requiredBank:${showBankList}`"
                    v-slot="{ errors }"
                    class="col-sm-9"
                  >
                    <b-form-select v-model="selectedBank" :options="banks"></b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
              </CContainer>
            </CRow>
            <CRow v-show="showBINList">
              <CContainer class="p-2 m-3 border border-light rounded">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.BIN") }}</CCol>
                  <ValidationProvider
                    name="BIN Number"
                    :rules="`requiredBIN:${showBINList}`"
                    v-slot="{ errors }"
                    class="col-sm-9"
                  >
                    <b-form-textarea
                      v-model="BINNumbers"
                      placeholder="Enter 6 digits (,) seperated bin numbers"
                      rows="5"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
              </CContainer>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.SUBTYPE") }}</CCol>
              <span class="form-group col-sm-9">
                <ValidationProvider
                  name="Promo Sub Type"
                  rules="requiredPromoType"
                  v-slot="{ errors }"
                >
                  <multiselect
                    v-model="promo.sub_type"
                    :options="sub_type_options"
                    :multiple="false"
                    track-by="value"
                    label="label"
                    :disabled="disable_subtype"
                    :placeholder="$t('PROMOS.PLACEHOLDER.SUBTYPE')"
                  ></multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </span>
            </CRow>
            <CRow v-show="showPayMethods">
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.TYPES.PAYMENT") }}</CCol>
              <span class="form-group col-sm-9">
                <ValidationProvider
                  name="Payment Methods"
                  :rules="`requiredPayMethods:${showPayMethods}`"
                  v-slot="{ errors }"
                >
                  <multiselect
                    v-model="selectedPaymentMethods"
                    :options="getPaymentMethodsByCategory()"
                    :multiple="true"
                    :placeholder="$t('PROMOS.TYPES.PAYMENT')"
                    track-by="unique_id"
                    label="name"
                  ></multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </span>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.TYPE") }}</CCol>
              <ValidationProvider
                name="Discount Type"
                rules="required"
                v-slot="{ errors }"
                class="col-sm-9"
              >
                <b-form-group>
                  <b-form-radio
                    v-model="promo.discount_type"
                    name="discountP-radios"
                    value="percentage"
                  >{{ $t("PROMOS.LABELS.PERCENTAGE") }}</b-form-radio>
                  <b-form-radio
                    v-model="promo.discount_type"
                    name="discountF-radios"
                    value="flat"
                  >{{ $t("PROMOS.LABELS.AMOUNT") }}</b-form-radio>
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>

            <CContainer v-show="showAmountDiscount" class="p-2 mb-3 border border-light rounded">
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.DISCOUNT") }}</CCol>
                <ValidationProvider
                  name="Discount Amount"
                  :rules="`requiredAmt:${showAmountDiscount}`"
                  v-slot="{ errors }"
                  class="col-sm-3"
                >
                  <b-input-group size="md" append="IDR">
                    <b-form-input
                      type="text"
                      v-model="promo.discount"
                      placeholder="IDR"
                      horizontal
                    />
                  </b-input-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="Min. purchase Amount"
                  :rules="`maxDiscountAmt:${promo.discount}`"
                  v-slot="{ errors }"
                  class="col-sm-6"
                >
                  <b-input-group size="md" prepend="Min Purchase">
                    <b-form-input
                      type="text"
                      v-model="promo.min_order_amount"
                      placeholder="Min. purchase IDR (optional)"
                      horizontal
                    />
                  </b-input-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
            </CContainer>
            <CContainer
              v-show="showPercentageDiscount"
              class="p-2 mb-3 border border-light rounded"
            >
              <CRow class="mb-2">
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.DISCOUNT_P") }}</CCol>
                <ValidationProvider
                  name="Discount Percentage"
                  :rules="`minmaxPercentage:1,100,${showPercentageDiscount}`"
                  v-slot="{ errors }"
                  class="col-sm-9"
                >
                  <b-input-group size="md" append="%" class="col-3 px-0">
                    <b-form-input
                      min="1"
                      max="100"
                      type="text"
                      v-model="promo.discount"
                      placeholder="%"
                    />
                  </b-input-group>
                  <span class="text-danger col-9 px-0">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow class="mb-2">
                <div class="col-sm-3" />
                <ValidationProvider
                  name="Min Order"
                  rules="onlyNumbers"
                  v-slot="{ errors }"
                  class="col-sm-9"
                >
                  <b-input-group size="md" prepend="Min Purchase">
                    <b-form-input
                      v-model="promo.min_order_amount"
                      type="text"
                      placeholder="Min. purchase IDR (optional)"
                      horizontal
                    />
                  </b-input-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow>
                <div class="col-sm-3" />
                <ValidationProvider
                  name="Max Discount"
                  :rules="`maxDiscount:${promo.min_order_amount}`"
                  v-slot="{ errors }"
                  class="col-sm-9"
                >
                  <b-input-group size="md" prepend="Max Discount">
                    <b-form-input
                      type="text"
                      v-model="promo.max_discount_amount"
                      placeholder="Max. discount IDR (optional)"
                      horizontal
                    />
                  </b-input-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
            </CContainer>
            <CRow>
              <CCol
                tag="label"
                sm="3"
                class="col-form-label"
              >{{ $t("PROMOS.LABELS.DEDUCTION_TYPE") }}</CCol>
              <span class="form-group col-sm-9">
                <ValidationProvider name="Deduction Type" :rules="`required`" v-slot="{ errors }">
                  <b-form-select v-model="promo.price_deduction_type" :options="deduction_type"></b-form-select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </span>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.LIMIT_TYPE") }}</CCol>
              <span class="form-group col-sm-9">
                <ValidationProvider
                  name="Promo Limit Type"
                  rules="requiredPromoType"
                  v-slot="{ errors }"
                >
                  <multiselect
                    v-model="promo.limit_type"
                    :options="limit_type_options"
                    :multiple="false"
                    track-by="value"
                    label="label"
                    :disabled="disable_limit"
                    :placeholder="$t('PROMOS.PLACEHOLDER.LIMIT')"
                  ></multiselect>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </span>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.LIMIT_VALUE") }}</CCol>
              <ValidationProvider
                name="Limit"
                rules="requiredOnlyNumbers"
                v-slot="{ errors }"
                class="form-group col-6"
              >
                <b-form-input
                  class="col-12 mb-0"
                  type="text"
                  v-model="promo.limit_value"
                  placeholder="Limit Value"
                  horizontal
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.FROM") }}</CCol>
              <ValidationProvider
                name="Effective From"
                rules="requiredDate"
                v-slot="{ errors }"
                class="col-sm-6"
              >
                <b-form-input
                  :min="getTodayDate()"
                  id="startDate"
                  v-model="start_date"
                  type="date"
                  onkeydown="return false"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                name="StartTime"
                rules="required"
                v-slot="{ errors }"
                class="col-sm-3"
              >
                <vue-timepicker id="startTime" v-model="start_time" format="HH:mm"></vue-timepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
            <CRow class="mt-3">
              <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.TO") }}</CCol>
              <ValidationProvider
                name="Effective To"
                rules="requiredDate"
                v-slot="{ errors }"
                class="col-sm-6"
              >
                <b-form-input
                  :min="getTodayDate()"
                  id="endDate"
                  v-model="end_date"
                  type="date"
                  onkeydown="return false"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                name="EndTime"
                rules="required"
                v-slot="{ errors }"
                class="col-sm-3"
              >
                <vue-timepicker id="endTime" v-model="end_time" format="HH:mm"></vue-timepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CContainer>
      <template #footer>
        <CButton @click="validatePromo()" color="durianprimary">{{ $t("COMMON.SAVE") }}</CButton>
      </template>
    </CModal>
    <CModal
      :title="$t('PROMOS.EDIT_CONFIRM')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="editConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.EDIT_CONFIRM_MSG")}}</label>
      </CContainer>
      <template #footer>
        <CButton
          @click="editConfirmationModal = false"
          color="lightborder"
        >{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton
          @click.prevent="createEditPromoReqObj(id)"
          color="lightborder"
        >{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>
    <CModal
      :title="$t('PROMOS.DUPLICATE_ALERT_TITLE')"
      color="danger"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="showDuplicatePromoAlert"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.DUPLICATE_PROMO_ALERT")}}</label>
      </CContainer>
      <template #footer>
        <CButton @click="clearPromoLabel" color="durianprimary">{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import PromoBuilder from "./../../models/builder/promoBuilder.js";
import "./../../validations/validationRules.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { getPromo, updatePromo, searchPromo } from "@/api/card_promo.api.js";
import Multiselect from "vue-multiselect";
import { listPaymentMethods } from "@/api/paymentMethods.api.js";

export default {
  name: "EditPromo",
  props: ["editPromoModal", "id"],
  components: {
    VueTimepicker,
    Multiselect
  },
  data() {
    return {
      paymentMethods: [],
      editConfirmationModal: false,
      showDuplicatePromoAlert: false,
      promo: {},
      promoChannelTypeBank: { name: "Card issued by Certain bank", id: 2 },
      promoChannelTypeBin: { name: "Specific BIN", id: 3 },
      promoChannelTypeCode: { name: "Promo Code", id: 1 },
      options: [],
      promoChannel: [],
      selectedBank: "",
      banks: ["BCA", "BNI", "BRI", "MANDIRI", "PERMATA"],
      start_date: "",
      start_time: "00:00",
      end_date: "",
      end_time: "00:00",
      BINNumbers: "",
      showPromoChannel: false,
      showPayMethods: false,
      deduction_type: ["total_price"],
      sub_type_options: [],
      limit_type_options: [],
      disable_limit: false,
      disable_subtype: false,
      selectedPaymentMethods: []
    };
  },
  computed: {
    showEditModal: {
      get: function() {
        return this.editPromoModal;
      },
      set: function(value) {
        this.$emit("update:editPromoModal", value);
      }
    },
    showBINList() {
      if (this.promoChannel.includes(3)) {
        return true;
      }
      return false;
    },
    showBankList() {
      if (this.promoChannel.includes(2)) {
        return true;
      }
      return false;
    },
    showPercentageDiscount() {
      if (this.promo.discount_type == "percentage") {
        return true;
      }
      return false;
    },
    showAmountDiscount() {
      if (this.promo.discount_type == "flat") {
        return true;
      }
      return false;
    }
  },
  methods: {
    getDate(ISODate) {
      var date = new Date(ISODate);
      date = this.moment(date).format("YYYY-MM-DD");
      return date;
    },
    getTime(ISODate) {
      let hr = new Date(ISODate).getHours();
      let min = new Date(ISODate).getMinutes();
      return this.addPadding(hr) + ":" + this.addPadding(min);
    },
    addPadding(str) {
      return str > 9 ? str : "0" + str;
    },
    addPromoChannel(optionID) {
      if (this.promoChannel.findIndex(x => x.id == optionID) === -1) {
        this.promoChannel.push(optionID);
      }
    },
    getPromoDetails() {
      getPromo(this.id)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.promo = response.data.data;
            this.formatPromoData();
          }
        })
        .catch(this.handleAPIError);
    },
    formatPromoData() {
      if (this.promo.type == "card_promos") {
        if (this.promo.promo_details.bin_list.length > 0) {
          this.addPromoChannel(this.promoChannelTypeBin.id);
          if (this.promo.promo_details.bin_list.length > 1) {
            this.BINNumbers = this.promo.promo_details.bin_list.join(",");
          } else {
            this.BINNumbers = this.promo.promo_details.bin_list[0].toString();
          }
        }
        if (this.promo.promo_details.bank_codes.length > 0) {
          this.addPromoChannel(this.promoChannelTypeBank.id);
          this.selectedBank = this.promo.promo_details.bank_codes[0];
        }
        this.showPayMethods = false;
        this.disable_subtype = true;
        this.disable_limit = true;
      } else {
        this.showPromoChannel = false;
        this.disable_subtype = false;
        this.disable_limit = false;
        if (this.promo.type == "va_promos") {
          this.selectedPaymentMethods = this.getUniquePayObjects(
            this.promo.promo_details.bank_codes
          );
          this.disable_subtype = true;
        } else {
          this.selectedPaymentMethods = this.getUniquePayObjects(
            this.promo.promo_details.ewallet_codes
          );
        }
        this.showPayMethods = true;
      }
      this.promo.sub_type = this.getSubTypeByValue(this.promo.sub_type);
      this.promo.limit_type = this.getLimitByValue(this.promo.limit_type);
      this.start_date = this.getDate(this.promo.starts_at);
      this.end_date = this.getDate(this.promo.ends_at);
      this.start_time = this.getTime(this.promo.starts_at);
      this.end_time = this.getTime(this.promo.ends_at);
      if (parseFloat(this.promo.min_order_amount) === 0) {
        this.promo.min_order_amount = this.resetToString(
          this.promo.min_order_amount
        );
      }
      if (parseFloat(this.promo.max_discount_amount) == 0) {
        this.promo.max_discount_amount = this.resetToString(
          this.promo.max_discount_amount
        );
      }
    },
    async validatePromo() {
      await this.$refs.validationObserver.validate().then(result => {
        if (result) {
          this.promo.starts_at = this.getISODate(
            this.start_date,
            this.start_time
          );
          this.promo.ends_at = this.getISODate(this.end_date, this.end_time);
          if (this.promo.starts_at > this.promo.ends_at) {
            this.showToaster("start date should be less than end date");
            return;
          }
          this.editConfirmationModal = true;
        }
      });
    },
    createEditPromoReqObj(id) {
      this.promo.code = this.promo.label;
      this.promo.sub_type = this.promo.sub_type.value;
      this.promo.limit_type = this.promo.limit_type.value;
      this.promo.starts_at = this.getISODate(this.start_date, this.start_time);
      this.promo.ends_at = this.getISODate(this.end_date, this.end_time);
      if (this.promo.type == "card_promos") {
        this.promo.promo_details.bin_list = this.getBINList();
        this.promo.promo_details.bank_codes = this.getBankCodes();
      } else if (this.promo.type == "va_promos") {
        let promo_details = {};
        promo_details.bank_codes = this.getUniquePayIDs();
        this.promo.promo_details = promo_details;
      } else if (this.promo.type == "ewallet_promos") {
        let promo_details = {};
        promo_details.ewallet_codes = this.getUniquePayIDs();
        this.promo.promo_details = promo_details;
      }
      this.updatePromo(id);
    },
    updatePromo(id) {
      updatePromo(this.promo, id)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.editConfirmationModal = false;
            this.showEditModal = false;
            this.$emit("updatelist");
          }
        })
        .catch(e => {
          this.handleAPIError(e);
        });
    },
    getISODate(date, time) {
      date = this.moment(date, "YYYY-MM-DD");
      var input = time.split(":");
      let hour = parseInt(input[0]);
      let min = parseInt(input[1]);
      let date_time = new Date(
        parseInt(date.format("YYYY")),
        parseInt(date.format("MM")) - 1,
        parseInt(date.format("DD")),
        hour,
        min,
        0,
        0
      );
      return date_time.toISOString();
    },
    getBankCodes() {
      let bank_codes = [];
      if (this.selectedBank !== "") {
        bank_codes.push(this.selectedBank);
      }
      return bank_codes;
    },
    getBINList() {
      let bin_list = [];
      let value = this.BINNumbers.trim();
      if (this.BINNumbers !== "") {
        if (value.includes(",")) {
          let nums = value.split(",");
          for (let index = 0; index < nums.length; index++) {
            bin_list.push(parseInt(nums[index]));
          }
        } else {
          bin_list.push(parseInt(value));
        }
      }
      return bin_list;
    },
    getPayMethods() {
      listPaymentMethods()
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.paymentMethods = this.formatPaymentMethods(
              response.data.data.payment_methods
            );
          }
        })
        .catch(this.handleAPIError);
    },
    formatPaymentMethods(data) {
      var groupBy = function(array, key) {
        return array.reduce(function(result, pay_method) {
          (result[pay_method[key]] = result[pay_method[key]] || []).push(
            pay_method
          );
          return result;
        }, {});
      };

      return groupBy(data, "category");
    },
    getPaymentMethodsByCategory() {
      if (this.promo.type != "") {
        let category = "";
        switch (this.promo.type) {
          case "card_promos":
            category = "CARD";
            break;
          case "va_promos":
            category = "VA";
            break;
          case "ewallet_promos":
            category = "EWALLET";
            break;
        }
        return this.paymentMethods[category];
      } else {
        return [];
      }
    },
    setLimitType(limit) {
      if (this.promo.type != "card_promos") {
        this.promo.limit_type = limit;
        if (limit != "") {
          this.disable_limit = true;
        } else {
          this.disable_limit = false;
        }
      }
    },
    getUniquePayObjects(payList) {
      let pl = [];
      payList.forEach(s => {
        let payMethod = this.getByUiqueID(s);
        pl.push(payMethod);
      });
      return pl;
    },
    getByUiqueID(id) {
      let payment_methods = this.getPaymentMethodsByCategory();
      let reqPayMethod = {};
      for (let i = 0; i < payment_methods.length; i++) {
        const p = payment_methods[i];
        if (p.unique_id == id) {
          reqPayMethod = p;
          break;
        }
      }
      return reqPayMethod;
    },
    getUniquePayIDs() {
      let payIDs = [];
      this.selectedPaymentMethods.forEach(element => {
        payIDs.push(element.unique_id);
      });
      return payIDs;
    },
    verifyPromoLabel(label) {
      searchPromo(label)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            if (response.data.data.labelExists) {
              this.showDuplicatePromoAlert = true;
            }
          }
        })
        .catch(this.handleAPIError);
    },
    clearPromoLabel() {
      this.promo.label = "";
      this.showDuplicatePromoAlert = false;
    },
    initialize() {
      this.promo = new PromoBuilder().build();
      this.sub_type_options = this.getPromoSubTypes();
      this.limit_type_options = this.getPromoLimitTypes();
      this.promo_types = this.getPromoTypes();
      this.getPayMethods();
    },
    getLimitByValue(value) {
      let limit = null;
      this.limit_type_options.forEach(element => {
        if (element.value == value) {
          limit = element;
        }
      });
      return limit;
    },
    getSubTypeByValue(value) {
      let subtype = null;
      this.sub_type_options.forEach(element => {
        if (element.value == value) {
          subtype = element;
        }
      });
      return subtype;
    }
  },
  mounted() {
    this.options = [this.promoChannelTypeBank, this.promoChannelTypeBin];
    this.getPromoDetails();
  },
  created() {
    this.initialize();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>